import Splide from '@splidejs/splide';

const heroSection = document.getElementById('hero--wrapper');
const header = document.getElementById('header');
const headerDesktop = document.getElementById('header--desktop');
const heroContent = document.getElementById('hero--content');
const btnPrm = document.querySelectorAll('.btn--prm');
const openModalButtonEl = document.getElementById('open--modal');
const closeModalButtonEl = document.getElementById('close--modal');
const splideCheck = document.querySelector('.splide__track');
const menu = document.getElementById('menu');

console.log('test');

openModalButtonEl.addEventListener('click', function () {
  menu.classList.remove('hidden');
});

closeModalButtonEl.addEventListener('click', function () {
  menu.classList.add('hidden');
});

// Loading of Projects on button click

// Define your projects
const projects = [
  { name: 'SouthAfrica', path: '/src/projects/southAfrica/SouthAfrica.html' },
  { name: 'Project2', path: '/src/projects/mexico/mexico.html' },
  { name: 'Project3', path: '/src/projects/antarctica/antarctica.html' },
  // Add more projects as needed
];

// Get the "Next Project" button element
const nextProjectButton = document.querySelector('#next-project');

// Function to get the current project index
const getCurrentProjectIndex = () => {
  const currentPath = window.location.pathname;

  // Normalize paths for comparison
  const normalizedCurrentPath = currentPath.replace(/\/+$/, ''); // Remove trailing slashes

  const index = projects.findIndex((project) => {
    const normalizedProjectPath = project.path.replace(/\/+$/, ''); // Remove trailing slashes

    return normalizedProjectPath === normalizedCurrentPath;
  });

  return index;
};

// Function to redirect to the next project
const redirectProject = () => {
  const currentIndex = getCurrentProjectIndex();
  if (currentIndex === -1) {
    return; // Current project not found in the array
  }

  // Calculate the index of the next project
  const nextIndex = (currentIndex + 1) % projects.length; // Loop back to the first project if at the end
  const nextProject = projects[nextIndex];

  // Redirect to the next project's path
  window.location.href = nextProject.path;
};

// Add click event listener to the "Next Project" button
if (nextProjectButton) {
  nextProjectButton.addEventListener('click', redirectProject);
}

// HEADER

header.classList.remove('header--sticky');
header.classList.add('header--static');

if (!heroSection) return;

const stickyNav = function (entries) {
  const [entry] = entries;
  // console.log(entry);

  if (!entry.isIntersecting) {
    header.classList.add('sticky');
    header.classList.remove('hidden');
    header.classList.add('header--sticky');
    header.classList.remove('header--static');
    headerDesktop.classList.add('sticky');
    headerDesktop.classList.remove('hidden');
    headerDesktop.classList.add('header--sticky');
    headerDesktop.classList.remove('header--static');
  } else {
    header.classList.remove('sticky');
    header.classList.remove('header--sticky');
    header.classList.add('header--static');
    headerDesktop.classList.remove('sticky');
    headerDesktop.classList.remove('header--sticky');
    headerDesktop.classList.add('header--static');
    // header.classList.add('hidden');
  }
};

const heroObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
});
heroObserver.observe(heroSection);

if (!splideCheck) return;

document.addEventListener('DOMContentLoaded', function () {
  const splide = new Splide('.splide', {
    type: 'loop',
    drag: 'free',
    snap: true,
    padding: '1rem',
    gap: '2rem',
    perPage: 1,
  });

  const carouselBg = document.querySelector('#carousel');
  const slides = document.querySelectorAll('.splide__slide');

  console.log(slides);

  const updateBackground = function () {
    let activeSlide = document.querySelector('.is-active');
  };
  // function updateBackground() {
  //   console.log('All slides', slides);
  //   console.log('The active slide:', activeSlide);
  //   const backgroundDiv = activeSlide.querySelector('.card.image-portrait');
  //   if (backgroundDiv) {
  //     var backgroundUrl = backgroundDiv.style.backgroundImage;
  //     console.log('Background URL: ', backgroundUrl); // Logging the background URL
  //     if (backgroundUrl) {
  //       // Extract the URL from the background-image property
  //       var urlMatch = backgroundUrl.match(/url\(["']?([^"']*)["']?\)/);
  //       var finalUrl = urlMatch ? urlMatch[1] : null;
  //       if (finalUrl) {
  //         document.getElementById('carousel').style.backgroundImage =
  //           'url(' + finalUrl + ')';
  //       }
  //     }
  //   }
  // }

  // splide.on('mounted', function () {
  //   console.log('mounted');
  //   updateBackground(); // Set initial background image after carousel is mounted
  // });

  // Update background image on moved event
  splide.on('moved', function () {
    console.log(slides);
  });

  splide.on('dragged', function () {
    console.log(slides);
  });

  splide.mount(); // Mount the carousel
});
// LAZY LOADING

// const imgTargets = document.querySelectorAll('div[data-src]');
// console.log(imgTargets);

// const loadImg = function (entries, observer) {
//   const [entry] = entries;
//   console.log(entry);

//   if (!entry.isIntersecting) return;

//   entry.target.src = entry.target.dataset.src;
// };

// const imgObserver = new IntersectionObserver(loadImg, {
//   root: null,
//   threshold: 0,
// });

// Dynamic Background Carousel

// imgTargets.forEach((img) => imgObserver.observe(img));
document.addEventListener('DOMContentLoaded', function () {
  const imgTargets = document.querySelectorAll('div[data-src]');

  const loadImg = function (entries, observer) {
    entries.forEach((entry) => {
      console.log(entry);
      if (entry.isIntersecting) {
        const lazyBackground = entry.target;
        const src = lazyBackground.dataset.src;
        console.log(src);
        lazyBackground.style.backgroundImage = `url(${src})`;
        // Remove observer once the image is loaded
        observer.unobserve(lazyBackground);
      }
    });
  };

  const imgObserver = new IntersectionObserver(loadImg, {
    root: null,
    threshold: 0,
  });

  imgTargets.forEach((img) => imgObserver.observe(img));
});
